import "styles/globals.css";

import type { AppProps } from "next/app";
import { Analytics } from "@vercel/analytics/react";
import { setup } from "twind";

import { PicketProvider } from "@picketapi/picket-react";
import type { AppState } from "@picketapi/picket-js";

const apiKey = process.env.NEXT_PUBLIC_PICKET_PUBLISHABLE_KEY;
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";

// do not let the app start if the api key isn't set
if (!apiKey) {
  throw new Error("NEXT_PUBLIC_PICKET_PUBLISHABLE_KEY is not set!");
}

const publicURL = process.env.NEXT_PUBLIC_VERCEL_URL;

// do not let the app start if the api key isn't set
if (!publicURL) {
  throw new Error("NEXT_PUBLIC_VERCEL_URL is not set!");
}

const baseURL = isProduction
  ? // if production always use picketapi
    "https://picketapi.com/api/v1"
  : // else set it based on the public URL
  publicURL.startsWith("http")
  ? `${publicURL}/api/v1`
  : `https://${publicURL}/api/v1`;

// by default, navigate to the returnTo location or clear the query parameters from the url after a successful login
const loginRedirectCallback = (appState?: AppState): void => {
  if (appState?.returnTo) {
    // replace location if returnTo exists
    return window.location.replace(appState.returnTo);
  }
  window.history.replaceState({}, document.title, window.location.pathname);
};

// setup twind
setup({
  preflight: false, // do not include base style reset (default: use tailwind preflight)
  darkMode: "class",
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <PicketProvider
      apiKey={apiKey as string}
      baseURL={baseURL}
      loginRedirectCallback={loginRedirectCallback}
      theme="light"
    >
      <Component {...pageProps} />
      <Analytics />
    </PicketProvider>
  );
}
export default MyApp;
